import __request__ from 'routing/request';
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface ReportHistorySummary {
    readonly generated_at: string;
    readonly recipients: string[];
    readonly id: string;
    readonly message: string;
    readonly has_asset: boolean;
    readonly status: 'STARTING' | 'RUNNING' | 'RETRYING' | 'GENERATED' | 'FAILURE' | 'DELIVERING' | 'RETRYING_DELIVERY' | 'SUCCESS' | 'DELIVERY_FAILURE';
}
interface EntityAttribute {
    readonly filterable: boolean;
    readonly related_property: string;
    readonly related_collection: string;
    readonly hidden: boolean;
    readonly sortable: boolean;
    readonly id: string;
    readonly type: 'STRING' | 'DATE' | 'DOUBLE' | 'INT' | 'LONG' | 'OBJECT_ID' | 'BOOLEAN';
    readonly title: string;
    readonly searchable: boolean;
    readonly filter_options: FilterOption[];
}
interface EntityDefaults {
    readonly sort: Sorting;
}
interface AbsoluteRange {
    readonly from: string;
    readonly to: string;
    readonly type: string;
}
interface ReportAttempt {
    readonly started: string;
    readonly attempt: number;
    readonly status: 'SUCCESS' | 'FAILURE' | 'DELIVERY_FAILURE' | 'RUNNING';
}
interface FilterOption {
    readonly title: string;
    readonly value: string;
}
type stringArray = string[];
interface ReportHistory {
    readonly timerange: AbsoluteRange;
    readonly generated_at: string;
    readonly report_id: string;
    readonly recipients: string[];
    readonly id: string;
    readonly status: 'STARTING' | 'RUNNING' | 'RETRYING' | 'GENERATED' | 'FAILURE' | 'DELIVERING' | 'RETRYING_DELIVERY' | 'SUCCESS' | 'DELIVERY_FAILURE';
    readonly attempts: ReportAttempt[];
}
interface Sorting {
    readonly id: string;
    readonly direction: 'ASC' | 'DESC';
}
interface PageListResponse_ReportHistorySummary {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly defaults: EntityDefaults;
    readonly query: string;
    readonly elements: ReportHistorySummary[];
    readonly attributes: EntityAttribute[];
    readonly sort: string;
    readonly order: 'asc' | 'desc';
}
/**
 * Get a history of generated reports
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function history(reportId: string, sort: 'status' | 'generated_at' = 'generated_at', page: number = 1, per_page: number = 50, order: 'asc' | 'desc' = 'asc', query?: string, filters?: stringArray): Promise<PageListResponse_ReportHistorySummary> {
    return __request__('GET', `/plugins/org.graylog.plugins.report/reports/${reportId}/history`, null, { 'page': page, 'per_page': per_page, 'sort': sort, 'order': order, 'query': query, 'filters': filters }, {
        'Accept': ['application/json']
    });
}
/**
 * Get details for a report job.
 */
export function jobStatus(reportId: string, jobId: string): Promise<ReportHistory> {
    return __request__('GET', `/plugins/org.graylog.plugins.report/reports/${reportId}/history/${jobId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Download the most recent successfull asset generated for a report.
 */
export function downloadAsset(reportId: string, jobId: string): Promise<unknown> {
    return __request__('GET', `/plugins/org.graylog.plugins.report/reports/${reportId}/history/${jobId}/download`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Download the assets generated by a specific attempt for a given job, if present.
 */
export function downloadAttemptAsset(reportId: string, jobId: string, attempt: number): Promise<unknown> {
    return __request__('GET', `/plugins/org.graylog.plugins.report/reports/${reportId}/history/${jobId}/${attempt}/download`, null, {}, {
        'Accept': ['application/json']
    });
}
